import { Typography } from '@material-ui/core';
import React, { FC } from 'react';

const NotFoundPage: FC = () => (
  <div title="NOT FOUND">
    <Typography variant="h2">404</Typography>
    <Typography variant="body1">お探しのページは見つかりませんでした。</Typography>
    <Typography variant="body1">URLが間違っているか、ページが存在しません。</Typography>
  </div>
);

export default NotFoundPage;
